<template>
  <b-card class="col-12 col-lg-6 c">
    <div class="col-12 text-center" v-if="loading">
      <img
        src="../../assets/images/loading.svg"
        style="width: 120px; height: 120px; margin: 0px auto"
      />
      <br />
      جاري الاستيراد...
    </div>
    <div ref="ff">
      <div class="form-group">
        <label for=""><h4>قم بإرفاق ملف المعلمين من نور</h4></label>
        <br /><br />
        <input
            id="ingredient_file"
            type="file"
        />
        <div id="progress-wrp">
            <div class="progress-bar"></div>
            <div class="status">0%</div>
        </div>
        <br />
        <small> بعد اختيار الملف، سوف يتم الإستيراد مباشرة. </small>
      </div>
    </div>
  </b-card>
</template>

<script>
var XLSX = require("xlsx");
import { BCard, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      loading: false,
      user: JSON.parse(localStorage.getItem('user')),
            tmpx: null
    };
  },
  created(){
      if(!checkPer("teachers|add")){
          this.$router.push('/NotPermitted')
          return false;
      }
    this.handleFileUpload()
  },
  methods: {
    handleFileUpload() {
        var g = this;
        var Upload = function (file) {
            this.file = file;
        };

        Upload.prototype.getType = function () {
            return this.file.type;
        };
        Upload.prototype.getSize = function () {
            return this.file.size;
        };
        Upload.prototype.getName = function () {
            return this.file.name;
        };
        Upload.prototype.doUpload = function () {
            var that = this;
            var formData = new FormData();

            // add assoc key values, this will be posts values
            formData.append("file", this.file, this.getName());
            formData.append("upload_file", true);

            $.ajax({
            type: "POST",
            url: "https://private-api.brmja.com/excel",
            xhr: function () {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) {
                myXhr.upload.addEventListener(
                    "progress",
                    that.progressHandling,
                    false
                );
                }
                return myXhr;
            },
            success: function (data) {
                // your callback here
                $("#progress-wrp").fadeOut("slow");
                var arr = [];
                data[0].forEach(function (student) {
                if (student[23]) {
                    student[23] = student[23].toString();
                    if (
                    parseInt(student[23]) != "NanN" &&
                    student[23].trim() != "" &&
                    !student[23].includes("ا")
                    ) {
                        arr.push({
                            name: student[21],
                            phone: student[5],
                            number: student[23]
                        });
                    }
                }
                });
                $.post(api + '/user/teachers/import', {
                    jwt: g.user.jwt,
                    arr: JSON.stringify(arr)
                }).then(function(r){
                    alert(r.response, r.status)
                }).fail(function(r){
                    alert("حدث خطأ", 200)
                })
            },
            error: function (error) {
                // handle error
                alert("حدث خطأ");
            },
            async: true,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            timeout: 60000,
            });
        };

        Upload.prototype.progressHandling = function (event) {
            var percent = 0;
            var position = event.loaded || event.position;
            var total = event.total;
            var progress_bar_id = "#progress-wrp";
            if (event.lengthComputable) {
            percent = Math.ceil((position / total) * 100);
            }
            // update progressbars classes so it fits your code
            $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
            $(progress_bar_id + " .status").text(percent + "%");
        };
        setTimeout(() => {
            $("#ingredient_file").on("change", function (e) {
            $("#progress-wrp").fadeIn("slow");
            var file = $(this)[0].files[0];
            var upload = new Upload(file);

            // maby check size or type here with upload.getSize() and upload.getType()

            // execute upload
            if(file.name.includes(".xlsx")){
                g.tmpx = file
                alert(`عفواً صيغة ملف xlsx غير مدعومة.<br> لكن لا تقلق.. تم تحويل الملف الى الصيغة المطلوبة<br>برجاء تحميل الملف من خلال الزر التالي واعادة رفعه.
                <br><br>
                <button class='btn btn-success dow'>
                    <i class='fa fa-download'></i>
                    تحميل الملف المحول
                </button>
                `)
                setTimeout(function(){
                $(".dow").click(function(){
                    $("#alert-modal___BV_modal_header_ > button").click()
                    var reader = new FileReader();
                    reader.onload = function(e) {
                        var data = e.target.result;
                        var workbook = XLSX.read(data, {
                            type: 'binary'
                        });
                        XLSX.writeFile(workbook, g.tmpx.name.replace(".xlsx", ".xls"));
                    };
                    reader.readAsBinaryString(g.tmpx);
                })
                }, 100)
            }else{
                upload.doUpload();
            }
            });
        }, 1000);

    },
  },
};
</script>

<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>